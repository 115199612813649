import React from 'react';
import { useSelector } from 'react-redux';

import {
  Container,
  Content,
  ContentCard,
  Pagination,
  Page,
  Seo,
} from '../components';

import { graphql } from 'gatsby';
import { H1, P, GalleryWrapper } from '../elements';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { v4 as uuidv4 } from 'uuid';

const Gallery = ({ pageContext, data, location }) => {
  const locale = useSelector((state) => state.localeString);
  const isDark = useSelector((state) => state.isDark);

  const { currentPage, numPages } = pageContext;
  const gallery = require('../utils/lingua')(locale)('Gallery');
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? `${locale}/gallery`
      : `${locale}/gallery${currentPage - 1}`;
  const nextPage = `${locale}/gallery${currentPage + 1}`;
  const posts = data.allMdx.edges;
  return (
    <Container pathname={location.pathname} isDark={isDark} locale={locale}>
      <Seo />
      <GalleryWrapper>
        <div className={'titleStatement'}>
          <H1 row={'3'}>{gallery.heading}</H1>
          <P>{gallery.statement}</P>
        </div>

        <div className={'MDX'}>
          <Page isDark={isDark}>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </Page>
        </div>
      </GalleryWrapper>
      <Content isDark={isDark}>
        <H1 textAlign="center" margin="0 0 1rem 0">
          {gallery.heading}
        </H1>
        <P color="dark2" text-align="center">
          {gallery.statement}
        </P>
        {posts.map((post) => (
          <ContentCard
            key={uuidv4()}
            date={post.node.frontmatter.date}
            title={post.node.frontmatter.title}
            excerpt={post.node.frontmatter.excerpt}
            slug={post.node.frontmatter.slug}
            featureImage={
              post.node.frontmatter.featureImage.childImageSharp.gatsbyImageData
            }
            locale={locale}
            isDark={isDark}
          />
        ))}
      </Content>
      <Pagination
        isFirst={isFirst}
        isLast={isLast}
        prevPage={prevPage}
        nextPage={nextPage}
        locale={locale}
        isDark={isDark}
      />
    </Container>
  );
};
export default Gallery;

export const galleryTemplateQuery = graphql`
  query GalleryTemplateQuery(
    $skip: Int!
    $limit: Int!
    $motive: String!
    $locale: String!
    $galleryWide: String!
  ) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        frontmatter: { motives: { eq: $motive }, locale: { eq: $locale } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            motives
            excerpt
            date
            featureImage {
              childImageSharp {
                gatsbyImageData(width: 200)
              }
            }
          }
        }
      }
    }
    mdx(id: { eq: $galleryWide }) {
      body
    }
  }
`;
